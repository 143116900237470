<script>
    import { onMount } from 'svelte';
    import { _ } from 'svelte-i18n';

    export let selectedCountry = "";
    export let item = {};
    let userLocale = 'en-GB';
    let priceField = 'price_gbp';
    let currency = 'GBP';
    
    let formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 0,
    });


    onMount(async function () {
        userLocale = navigator.language;
        if(selectedCountry == 'GB') {
            userLocale = 'en-GB';
            priceField = 'price_gbp';
            currency = 'GBP';
        } else if(selectedCountry == 'US'){
            userLocale = 'en-US';
            priceField = 'price_usd';
            currency = 'USD';
        } else if(selectedCountry == 'TR'){
            userLocale = 'tr-TR';
            priceField = 'na';
            currency = 'na';
        } else {
            priceField = 'price_eur';
            currency = 'EUR';
        }
    });

</script>

<span>
    {#if priceField == 'na'}
    {$_('getAQuote')}
    {:else}
    { (new Intl.NumberFormat(userLocale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0,
    })).format(item[priceField]) }
    {/if}
</span>

<style>
</style>
