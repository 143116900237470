<script>
    import "flag-icons/css/flag-icons.css";
    import "global.css";
    import { fly } from 'svelte/transition';
    import { onMount } from 'svelte';
    import { _ } from 'svelte-i18n';
    import examples from 'libphonenumber-js/examples.mobile.json';

    import { getExampleNumber, getCountries, parse, format, AsYouType, parsePhoneNumberWithError, ParseError, getCountryCallingCode } from 'libphonenumber-js';
    import moment from 'moment-timezone';

    import {
        Button,
        Card,
        TextField,
        Divider,
        Loading,
        Label,
        FormField
    } from 'attractions';

    let visible = false;
    let fullname = '';
    let phone = '';
    let email = '';
    let loading = false;
    let isAgentSet = false;
    let agentName = '';
    let agentPhone = '';
    let error = false;
    let showButton = false;
    let phoneError = "";
    let countries = getCountries();
    let isValidPhone = false;
    let selectedCountry = ""
    let exampleNumber = getExampleNumber("TR", examples);
    let countryNames = {
        "BD": "Bangladesh",
        "BE": "Belgium",
        "BF": "Burkina Faso",
        "BG": "Bulgaria",
        "BA": "Bosnia and Herzegovina",
        "BB": "Barbados",
        "WF": "Wallis and Futuna",
        "BL": "Saint Barthelemy",
        "BM": "Bermuda",
        "BN": "Brunei",
        "BO": "Bolivia",
        "BH": "Bahrain",
        "BI": "Burundi",
        "BJ": "Benin",
        "BT": "Bhutan",
        "JM": "Jamaica",
        "BV": "Bouvet Island",
        "BW": "Botswana",
        "WS": "Samoa",
        "BQ": "Bonaire, Saint Eustatius and Saba ",
        "BR": "Brazil",
        "BS": "Bahamas",
        "JE": "Jersey",
        "BY": "Belarus",
        "BZ": "Belize",
        "RU": "Russia",
        "RW": "Rwanda",
        "RS": "Serbia",
        "TL": "East Timor",
        "RE": "Reunion",
        "TM": "Turkmenistan",
        "TJ": "Tajikistan",
        "RO": "Romania",
        "TK": "Tokelau",
        "GW": "Guinea-Bissau",
        "GU": "Guam",
        "GT": "Guatemala",
        "GS": "South Georgia and the South Sandwich Islands",
        "GR": "Greece",
        "GQ": "Equatorial Guinea",
        "GP": "Guadeloupe",
        "JP": "Japan",
        "GY": "Guyana",
        "GG": "Guernsey",
        "GF": "French Guiana",
        "GE": "Georgia",
        "GD": "Grenada",
        "GB": "United Kingdom",
        "GA": "Gabon",
        "SV": "El Salvador",
        "GN": "Guinea",
        "GM": "Gambia",
        "GL": "Greenland",
        "GI": "Gibraltar",
        "GH": "Ghana",
        "OM": "Oman",
        "TN": "Tunisia",
        "JO": "Jordan",
        "HR": "Croatia",
        "HT": "Haiti",
        "HU": "Hungary",
        "HK": "Hong Kong",
        "HN": "Honduras",
        "HM": "Heard Island and McDonald Islands",
        "VE": "Venezuela",
        "PR": "Puerto Rico",
        "PS": "Palestinian Territory",
        "PW": "Palau",
        "PT": "Portugal",
        "SJ": "Svalbard and Jan Mayen",
        "PY": "Paraguay",
        "IQ": "Iraq",
        "PA": "Panama",
        "PF": "French Polynesia",
        "PG": "Papua New Guinea",
        "PE": "Peru",
        "PK": "Pakistan",
        "PH": "Philippines",
        "PN": "Pitcairn",
        "PL": "Poland",
        "PM": "Saint Pierre and Miquelon",
        "ZM": "Zambia",
        "EH": "Western Sahara",
        "EE": "Estonia",
        "EG": "Egypt",
        "ZA": "South Africa",
        "EC": "Ecuador",
        "IT": "Italy",
        "VN": "Vietnam",
        "SB": "Solomon Islands",
        "ET": "Ethiopia",
        "SO": "Somalia",
        "ZW": "Zimbabwe",
        "SA": "Saudi Arabia",
        "ES": "Spain",
        "ER": "Eritrea",
        "ME": "Montenegro",
        "MD": "Moldova",
        "MG": "Madagascar",
        "MF": "Saint Martin",
        "MA": "Morocco",
        "MC": "Monaco",
        "UZ": "Uzbekistan",
        "MM": "Myanmar",
        "ML": "Mali",
        "MO": "Macao",
        "MN": "Mongolia",
        "MH": "Marshall Islands",
        "MK": "Macedonia",
        "MU": "Mauritius",
        "MT": "Malta",
        "MW": "Malawi",
        "MV": "Maldives",
        "MQ": "Martinique",
        "MP": "Northern Mariana Islands",
        "MS": "Montserrat",
        "MR": "Mauritania",
        "IM": "Isle of Man",
        "UG": "Uganda",
        "TZ": "Tanzania",
        "MY": "Malaysia",
        "MX": "Mexico",
        "IL": "Israel",
        "FR": "France",
        "IO": "British Indian Ocean Territory",
        "SH": "Saint Helena",
        "FI": "Finland",
        "FJ": "Fiji",
        "FK": "Falkland Islands",
        "FM": "Micronesia",
        "FO": "Faroe Islands",
        "NI": "Nicaragua",
        "NL": "Netherlands",
        "NO": "Norway",
        "NA": "Namibia",
        "VU": "Vanuatu",
        "NC": "New Caledonia",
        "NE": "Niger",
        "NF": "Norfolk Island",
        "NG": "Nigeria",
        "NZ": "New Zealand",
        "NP": "Nepal",
        "NR": "Nauru",
        "NU": "Niue",
        "CK": "Cook Islands",
        "XK": "Kosovo",
        "CI": "Ivory Coast",
        "CH": "Switzerland",
        "CO": "Colombia",
        "CN": "China",
        "CM": "Cameroon",
        "CL": "Chile",
        "CC": "Cocos Islands",
        "CA": "Canada",
        "CG": "Republic of the Congo",
        "CF": "Central African Republic",
        "CD": "Democratic Republic of the Congo",
        "CZ": "Czech Republic",
        "CY": "Cyprus",
        "CX": "Christmas Island",
        "CR": "Costa Rica",
        "CW": "Curacao",
        "CV": "Cape Verde",
        "CU": "Cuba",
        "SZ": "Swaziland",
        "SY": "Syria",
        "SX": "Sint Maarten",
        "KG": "Kyrgyzstan",
        "KE": "Kenya",
        "SS": "South Sudan",
        "SR": "Suriname",
        "KI": "Kiribati",
        "KH": "Cambodia",
        "KN": "Saint Kitts and Nevis",
        "KM": "Comoros",
        "ST": "Sao Tome and Principe",
        "SK": "Slovakia",
        "KR": "South Korea",
        "SI": "Slovenia",
        "KP": "North Korea",
        "KW": "Kuwait",
        "SN": "Senegal",
        "SM": "San Marino",
        "SL": "Sierra Leone",
        "SC": "Seychelles",
        "KZ": "Kazakhstan",
        "KY": "Cayman Islands",
        "SG": "Singapore",
        "SE": "Sweden",
        "SD": "Sudan",
        "DO": "Dominican Republic",
        "DM": "Dominica",
        "DJ": "Djibouti",
        "DK": "Denmark",
        "VG": "British Virgin Islands",
        "DE": "Germany",
        "YE": "Yemen",
        "DZ": "Algeria",
        "US": "United States",
        "UY": "Uruguay",
        "YT": "Mayotte",
        "UM": "United States Minor Outlying Islands",
        "LB": "Lebanon",
        "LC": "Saint Lucia",
        "LA": "Laos",
        "TV": "Tuvalu",
        "TW": "Taiwan",
        "TT": "Trinidad and Tobago",
        "TR": "Turkey",
        "LK": "Sri Lanka",
        "LI": "Liechtenstein",
        "LV": "Latvia",
        "TO": "Tonga",
        "LT": "Lithuania",
        "LU": "Luxembourg",
        "LR": "Liberia",
        "LS": "Lesotho",
        "TH": "Thailand",
        "TF": "French Southern Territories",
        "TG": "Togo",
        "TD": "Chad",
        "TC": "Turks and Caicos Islands",
        "LY": "Libya",
        "VA": "Vatican",
        "VC": "Saint Vincent and the Grenadines",
        "AE": "United Arab Emirates",
        "AD": "Andorra",
        "AG": "Antigua and Barbuda",
        "AF": "Afghanistan",
        "AI": "Anguilla",
        "VI": "U.S. Virgin Islands",
        "IS": "Iceland",
        "IR": "Iran",
        "AM": "Armenia",
        "AL": "Albania",
        "AO": "Angola",
        "AQ": "Antarctica",
        "AS": "American Samoa",
        "AR": "Argentina",
        "AU": "Australia",
        "AT": "Austria",
        "AW": "Aruba",
        "IN": "India",
        "AX": "Aland Islands",
        "AZ": "Azerbaijan",
        "IE": "Ireland",
        "ID": "Indonesia",
        "UA": "Ukraine",
        "QA": "Qatar",
        "MZ": "Mozambique"
    };
    let countryPriority = ['GB', 'DE', 'FR', 'ES', 'IT', 'TR', 'NO', 'NL', 'US', 'IE'];
    let sortedCountries = [];
    let formValid = false;
    let showError = false;
    let timeZone = moment.tz.guess();
    
    $: if(selectedCountry) {
        if(selectedCountry.length > 0) {
            phone = "+" + getCountryCallingCode(selectedCountry);
            exampleNumber = getExampleNumber(selectedCountry, examples)
        } else {
            exampleNumber = getExampleNumber("TR", examples)
        }
        isValidPhone = false;
    }

    $: if (phone) {
        isValidPhone = false;
        if(phone.indexOf('+') != 0) {
            phone = "+" + getCountryCallingCode(selectedCountry) + phone;
        }
        try {
            const phoneNumber = parsePhoneNumberWithError(phone);
            phone = new AsYouType().input(phone);
            phoneError = "";
            isValidPhone = phoneNumber.isValid();
        } catch (error) {
            if (error instanceof ParseError) {
                // Not a phone number, non-existent country, etc.
                console.log(error.message);
                phoneError = error.message;
            } else {
                throw error;
            }
        }
	}

    $: if (fullname.length > 0 && email.length > 0 && phone.length > 0) {
            formValid = validateEmail(email) && isValidPhone;
        } else {
            formValid = false;
        }
    
    onMount(async function () {
        console.log("timezone:", timeZone);
        agentPhone = getFromStorage('agentPhone');
        agentName = getFromStorage('agentName');

        //const response = await fetch("https://api.freegeoip.app/json/?apikey=e6f4d470-a5d9-11ec-a9b4-cb9e61dc9f21");
        //const response = await fetch("https://admin.htglobal.com.tr/en/geoip");
        //const data = await response.json();
        for (var cnt in countryNames) {
            if(countryPriority.indexOf(cnt) == -1 && countries.indexOf(cnt) != -1) {
                sortedCountries.push([cnt, countryNames[cnt]]);
            }
        }

        sortedCountries.sort(function(a, b) {
            return a[1].localeCompare(b[1]);
        });
        /*
        fetch("https://ip2c.org/s").then(response => {
            return response.text();
        }).then(data => {
            selectedCountry= data.split(';')[1];
            exampleNumber = getExampleNumber(selectedCountry, examples);
        }).catch(err => {
            console.log('error on country detect', err)
            selectedCountry = 'GB';
            exampleNumber = getExampleNumber(selectedCountry, examples);
        });
        */
//        fetch("https://admin.htglobal.com.tr/api/public/geoip").then(response => {
        fetch("https://admin.godent.com/geoip").then(response => {
            return response.json();
        }).then(data => {
            selectedCountry = data.country_code;
            exampleNumber = getExampleNumber(selectedCountry, examples);
        }).catch(err => {
            console.log('error on country detect', err)
            selectedCountry = 'GB';
            exampleNumber = getExampleNumber(selectedCountry, examples);
        });

        // selectedCountry = data.country_code;

        if (
            agentPhone &&
            agentName &&
            agentPhone.length > 0 &&
            agentName.length > 0
        ) {
            isAgentSet = true;
        }
        showButton = true;
    });

    
    function toggle() {
        if (isAgentSet) {
            window.open(`https://wa.me/${agentPhone}`, '_blank');
            visible = false;
        } else {
            loading = false;
            visible = !visible;
        }
    }

    function validateEmail(email) {
        const re =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    function addToStorage(key, value) {
        var expiryPeriod = 24 * 60 * 60 * 1000;
        var expiryDate = new Date().getTime() + expiryPeriod;
        var record = { value: value, expiryDate: expiryDate };
        localStorage.setItem(key, JSON.stringify(record));
    }

    function getFromStorage(key) {
        var currDate = new Date().getTime();

        var item = JSON.parse(localStorage.getItem(key));
        var isValid = item && currDate < item.expiryDate;

        if (isValid) {
            return item.value;
        }
    }

    function getSourceParam(param) {
        var currDate = new Date().getTime();

        var sourceParam = JSON.parse(localStorage.getItem(param));
        var isSourceParamValid =
            sourceParam && currDate < sourceParam.expiryDate;

        if (isSourceParamValid) {
            return sourceParam.value;
        }
        let cookieVal = getCookie(param);
        if (cookieVal) {
            return cookieVal;
        }

        return getParam(param);
    }

    function getCookie(cname) {
        var name = cname + '=';
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }

    function getParam(p) {
        var match = RegExp('[?&]' + p + '=([^&]*)').exec(
            window.location.search
        );
        return match && decodeURIComponent(match[1].replace(/\\+/g, ' '));
    }

    function getParams() {
        let utmParams = [
            'utm_source',
            'utm_campaign',
            'utm_medium',
            'utm_term',
            'utm_content',
            'referer_id',
            'refagent',
            'source',
            'gclid',
            'utm_id'
        ];
        let cookieParams = {};
        utmParams.forEach((element) => {
            cookieParams[element] = getSourceParam(element);
        });

        const params = new URLSearchParams(window.location.search);
        let queryParams = {};

        for (const param of params.keys()) {
            queryParams[param] = params.get(param);
        }
        let result = {
            queryParams: queryParams,
            cookieParams: cookieParams,
        };
        return result;
    }
    function getLanguage() {
        let path = window.location.pathname.split('/', 2);
        let lang = path[1];
        if (lang.length > 0 && lang.length < 3) {
            return lang;
        }
        return 'en';
    }

    async function digestMessage(message) {
        const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
        const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
        const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
        const hashHex = hashArray
            .map((b) => b.toString(16).padStart(2, '0'))
            .join(''); // convert bytes to hex string
        return hashHex;
    }

    async function sendForm() {
        console.log('has error?', error);
        console.log(fullname.length, email.length, phone);
        showError = true;
        email = email.replace(/\s/g, '');
        if (!(fullname.length > 0 && email.length > 0 && phone.length > 0)) {
            error = true;
            return;
        } else {
            error = false;
        }
        if (!validateEmail(email)) {
            error = true;
            return;
        } else {
            error = false;
        }
        if(!isValidPhone) {
            error = true;
            return;
        } else {
            error = false;
        }

        loading = true;
        let allParams = getParams();
        let data = {
            fullname: fullname,
            email: email,
            phone: phone,
            locale: getLanguage(),
            timezone: timeZone,
            ...allParams,
        };
        let dataLayerData = {
            'event': 'gaEvent',
            'eventCategory':'Form',
            'eventAction':'Lead',
            'eventLabel':'Whatsapp', // statik olarak gelecektir.
            'user_name': await digestMessage(fullname),
            'user_phone': await digestMessage(phone),
            'user_mail': await digestMessage(email),
            'eventNonInteraction': false,
        }

        data['dataLayerData'] = dataLayerData;
        window.dataLayer = window.dataLayer || [];
        dataLayer.push(dataLayerData);

        let url = 'https://admin.godent.com/api/lead';
        ///let url = 'http://localhost:8084/api/lead';
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((data) => {
                if (typeof gtag_report_conversion_whatsapp === 'function') {
                    gtag_report_conversion_whatsapp();
                }
                loading = false;
                agentPhone = data['agent_phone'];
                agentName = data['agent_name'];
                addToStorage('agentPhone', agentPhone);
                addToStorage('agentName', agentName);
                isAgentSet = true;
                window.open(`https://wa.me/${data['agent_phone']}`, '_blank');
            })
            .catch((error) => {
                loading = false;
                console.log(error);
                return [];
            });
    }
</script>

<main>
    {#if visible}
        <div class="opacity-bg"></div>
        <p
            class="waform"
            in:fly={{ x: 200, duration: 500 }}
            out:fly={{ x: 200, duration: 500 }}
        >
            {#if !isAgentSet}
                <Card>
                    <span class="close-btn" on:click={toggle}>X</span>
                    <img class="wa-button-logo" alt="godent-logo" src="https://www.godent.com/assets/svg/2a90ccd69e2530076147306c1b7d0b2c.svg"
                         style="
                    max-width: 80px;
                    padding-top: 25px;"/>
                    <p class="wa-button">{$_('title')}</p>
                    <TextField
                        placeholder={$_('nameSurname')}
                        bind:value={fullname}
                        required
                    />
                    {#if fullname.length < 2 && showError}
                    <Label small class="error">Invalid Name</Label>
                    {/if}
                    <div class="flex">
                        <div class="select-sm fi fi-{selectedCountry.toLocaleLowerCase()}"></div>
                        <select bind:value={selectedCountry} class="select-sm select-sm-hidden">
                            {#each countryPriority as country}
                                <option value={country}>
                                    {countryNames[country]}
                                </option>
                            {/each}
                            <option disabled value="">__________</option>
                            {#each sortedCountries as country}
                                <option value={country[0]}>
                                    {countryNames[country[0]]}
                                </option>
                            {/each}
                        </select>
                        <div class="full-width">
                            <TextField
                            placeholder={$_('phone')+` ex: ${exampleNumber.formatInternational()}`}
                            bind:value={phone}
                            noSpinner
                            required
                            inputClass="full-width"
                            style="width:100%"
                            />                

                        </div>
                        </div>
                        {#if !isValidPhone && showError}
                        <Label small class="error">Invalid Phone</Label>
                        {/if}
    
                    <TextField
                        placeholder={$_('email')}
                        bind:value={email}
                        required
                    />
                    {#if !validateEmail(email) && showError}
                    <Label small class="error">Invalid Email</Label>
                    {/if}
                    {#if !loading}
                        <Button filled on:click={sendForm}>{$_('send')}</Button>
                    {/if}
                    {#if loading}
                        <Loading />
                    {/if}
                </Card>
            {/if}
            {#if isAgentSet}
                <Card>
                    <p class="wa-button">
                        <a class="chat-btn" href={`https://wa.me/${agentPhone}`} target="_blank">
                            {$_('chatWith').replace('{agentName}', agentName)}
                        </a>
                    </p>
                </Card>
            {/if}
        </p>
    {/if}
    {#if showButton}
        <div
            in:fly={{ x: 200, duration: 500 }}
            out:fly={{ x: 200, duration: 500 }}
        >
            <Button round on:click={toggle}>
                <div>
                    <img
                        in:fly={{ y: 200, duration: 2000 }}
                        src="https://www.godent.com/assets/files/whatsapp-icon.svg"
                        alt="whatsapp logo"
                        class="icon boop"
                    />
                </div>
            </Button>
        </div>
    {/if}
</main>

<style>
    main {
        color: rgb(51, 83, 96);
        position: fixed;
        right: 30px;
        bottom: 30px;
        text-align: center;
        padding: 1em;
        max-width: 240px;
        margin: 0 auto;
        z-index: 99;
    }
    .waform {
        position: fixed;
        right: 30px;
        bottom: 70px;
        max-width: 240px;
    }
    .icon {
        position: fixed;
        right: 30px;
        bottom: 30px;
        color: #075e54;
        width: 60px;
        height: 60px;
    }
    .flex {
        display: flex;
    }
    .select-sm {
        height: 2.6em;
        width: 50px;
        overflow: hidden;
        white-space: nowrap;
        box-sizing: border-box;
        font-family: "Ubuntu", sans-serif;
        margin-bottom: 10px !important;
        background-color: transparent !important;
        font-size: 12px !important;
        font-weight: 400 !important;
    }
    .select-sm-hidden {
        opacity: 0 !important;
        margin-left: -50px;
    }
    .full-width {
        width: 100% !important;
    }
    .error {
        border-color: red !important;
    }
    @keyframes boop {
		0% {
			transform: rotate(0deg);
		}
		20% {
			transform: rotate(-15deg);
		}
		40% {
			transform: rotate(15deg);
		}
		60% {
			transform: rotate(-7deg);
		}
		80% {
			transform: rotate(7deg);
		}
		90% {
			transform: rotate(-3deg);
		}
		95% {
			transform: rotate(2deg);
		}
		100% {
			transform: rotate(0deg);
		}
	}
    .boop {
		display: inline-block;
		animation: 1s boop forwards running;
        animation-iteration-count: 5;
	}
    @media (min-width: 640px) {
        main {
            max-width: none;
        }
        .boop:hover {
            display: inline-block;
            animation: 1s boop forwards running;
            animation-iteration-count: infinite;
        }

    }
    @media (max-width: 640px) {
        main {
            max-width: none;
        }
        .waform {
            max-width: 90%;
            width: 400px;
        }
        .boop {
            display: inline-block;
            animation: 1s boop forwards running;
            animation-iteration-count: 15;
        }
    }


</style>
