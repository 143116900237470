
<script>
    import "global.css";
    import { onMount } from 'svelte';
    import { _ } from 'svelte-i18n';

    export let selectedCountry = "";
    export let products = [];
    let formatter;
    let priceField;
    let currency = "GBP";
    let userLocale = "en-GB";
        
    onMount(async function () {
        console.log("on package list:", selectedCountry)
        userLocale = navigator.language;
        if(selectedCountry == 'GB') {
            userLocale = 'en-GB';
            priceField = 'price_gbp';
            currency = 'GBP';
        } else if(selectedCountry == 'US'){
            userLocale = 'en-US';
            priceField = 'price_usd';
            currency = 'USD';
        } else if(selectedCountry == 'TR'){
            userLocale = 'tr-TR';
            priceField = 'na';
            currency = 'na';
        } else {
            priceField = 'price_eur';
            currency = 'EUR';
        }
    });

</script>

<main class="styled-table-container">
    <table class="styled-table">
        <thead>
            <tr>
                <th>Treatment</th>
                <th class="right-aligned">Price</th>
            </tr>
        </thead>
        <tbody>
            {#each products as item}
            {#if item.type == "treatment" && item.is_package}
            <tr>
                <td>
                    <div class="title">{ item.title }</div>
                    {#if item.subtitle}
                    <div class="subtitle">{ item.subtitle }</div>
                    {/if}
                </td>
                <td class="right-aligned price">
                    {#if priceField == 'na'}
                    {$_('getAQuote')}
                    {:else}
                    { (new Intl.NumberFormat(userLocale, {
                        style: 'currency',
                        currency: currency,
                        minimumFractionDigits: 0,
                    })).format(item[priceField]) }
                    {/if}
                </td>
            </tr>
            {/if}
            {/each}        
        </tbody>
    </table>
</main>

<style>
.styled-table-container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: inherit;
}
.styled-table {
    background-color: inherit;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: Overpass, sans-serif;
    min-width: 400px;
    max-width: 800px;
    box-shadow: 0 0 0px rgba(0, 0, 0, 0.15);
    flex: 1;
}
.styled-table thead tr {
    background-color: transparent;
    color: #666666;
    opacity: 0.5;
    font-size: large;
    text-align: left;
    border-width: 0px 0px 1px 0px;
    border-style: solid;
    border-color: rgba(51, 83, 96, 0.35);
}
.styled-table th,
.styled-table td {
    padding: 15px 20px;
    color: #335360;
    margin: 10px;
}
.styled-table th.right-aligned ,
.styled-table td.right-aligned  {
    text-align: right;
}

.styled-table .price  {
    color: #666666;
    font-family: Overpass, sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 1.9em;
}
.styled-table .subtitle  {
    font-family: Overpass, sans-serif;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 1.8em;
}
.styled-table .title  {
    font-family: Overpass, sans-serif;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 1.6em;
}

.styled-table tbody tr {
    border-width: 0px 0px 1px 0px;
    border-style: solid;
    border-color: rgba(51, 83, 96, 0.35);
}

/*
.styled-table tbody tr:nth-of-type(odd) {
    background-color: #f3f3f3;
}
*/
.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid rgba(51, 83, 96, 0.35);;
}
</style>
