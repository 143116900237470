// src/i18n.js
import { register, init } from 'svelte-i18n';

register('en', () => import('./translations/en.json'));
register('tr', () => import('./translations/tr.json'));
register('fr', () => import('./translations/fr.json'));
register('es', () => import('./translations/es.json'));
register('de', () => import('./translations/de.json'));
register('no', () => import('./translations/no.json'));
register('it', () => import('./translations/it.json'));
register('nl', () => import('./translations/nl.json'));
// en, en-US and pt are not available yet

function getLanguage() {
    let path = window.location.pathname.split('/', 2);
    let lang = path[1];
    if (lang.length > 0 && lang.length < 3) {
        return lang;
    }
    return 'en';
}

init({
    fallbackLocale: 'en',
    initialLocale: getLanguage(),
});
