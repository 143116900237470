import WAButton from './WAButton.svelte';
import PriceList from './PriceList.svelte';
import PackageList from './PackageList.svelte';
import Package from './Package.svelte';
import './i18n.js';

function getParam(p) {
    var match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\\+/g, ' '));
}

function getCountry(cb) {
    let selectedCountry;
    //fetch('https://admin.htglobal.com.tr/api/public/geoip')
    fetch('https://admin.godent.com/geoip')
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            selectedCountry = data.country_code;
            console.log(selectedCountry);
            if (getParam('agency')) {
                selectedCountry = getParam('agency').toUpperCase();
            }
            cb(selectedCountry);
        })
        .catch((err) => {
            console.log('err', err);
            selectedCountry = 'GB';
            cb(selectedCountry);
        });
}
function getProducts(selectedCountry) {
    let products;
    fetch('https://admin.godent.com/api/public/products')
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            products = data;
            createComponents(selectedCountry, products);
        })
        .catch((err) => {
            console.log(err, selectedCountry);
            products = [];
        });
}

function createComponents(selectedCountry, products) {
    if (document.querySelector('#wassistant-button')) {
        new WAButton({
            target: document.querySelector('#wassistant-button'),
        });
    }
    if (document.querySelector('#messaging-button')) {
        new MessagingButton({
            target: document.querySelector('#messaging-button'),
        });
    }

    if (document.querySelector('#dynamic-price-list')) {
        new PriceList({
            target: document.querySelector('#dynamic-price-list'),
            props: {
                products: products,
                selectedCountry: selectedCountry,
            },
        });
    }
    if (document.querySelector('#dynamic-package-list')) {
        new PackageList({
            target: document.querySelector('#dynamic-package-list'),
            props: {
                products: products,
                selectedCountry: selectedCountry,
            },
        });
    }

    let packages = [4, 5, 6, 7, 8, 9, 15, 17, 19, 16, 18, 20];
    packages.forEach((element) => {
        let item;
        products.forEach((product) => {
            if (product.id == element) {
                item = product;
            }
        });
        let packageId = `#package-${element} span`;
        if (document.querySelector(packageId)) {
            new Package({
                target: document.querySelector(packageId),
                props: {
                    item: item,
                    selectedCountry: selectedCountry,
                },
            });
        }
    });
}

getCountry(getProducts);
